<!-- bact 37 -->
<template>
  <div class="course-list">
    <v-container>
      <!-- thumbnail -->
      <section class="heroSlider-sertion">
        <div class="uk-container">
          <div uk-slider="center: true" dir="ltr">
            <div
              class="uk-position-relative uk-visible-toggle uk-light"
              tabindex="-1"
            >
              <ul class="uk-slider-items uk-child-width-1-1 uk-grid uk-slider">
                <li>
                  <div
                    class="uk-card uk-card-default uk-grid-collapse uk-flex-middle uk-margin"
                    uk-grid
                  >
                    <div class="uk-width-3-3@m uk-border-radius-l">
                      <img
                        :src="getBased(CoursesList.category.thumbnail)"
                        alt=""
                        uk-cover
                        style="width: 100%"
                      />
                      <!-- <div class=" uk-position-cover"></div> -->
                      <canvas width="600" height="400"></canvas>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
          </div>
        </div>
      </section>
      <!-- course header -->
      <div class="d-flex justify-space-between align-center mb-6 title" flat>
        <div>
          <h3>
            <b>{{ CoursesList.category.title }}</b>
          </h3>
        </div>
        <div>
          <h3>
            <b class="main-color"> {{ $t("coursesList.coursesCount") }} :</b>
            {{ CoursesList.category.courseCount }}
          </h3>
        </div>
      </div>
      <!-- courses list item -->
      <div
        class="list"
        flat
        tile
        v-for="course in CoursesList.courses"
        :key="course.id"
      >
        <CoursesListItem :course="course" />
      </div>
      <!-- course modal info -->
      <course-info-modal></course-info-modal>
    </v-container>
  </div>
</template>

<script>
import CourseInfoModal from "../../../../core/CourseInfoModal/CourseInfoModal.vue";
import CoursesListItem from "./components/CoursesListItem";
import { mapActions, mapState } from "vuex";

export default {
  name: "CoursesList",

  components: {
    CoursesListItem,
    CourseInfoModal
  },

  data: function() {
    return {};
  },

  created() {
    this.getCoursesList(this.$route.params.id);
  },

  methods: {
    ...mapActions("CourseArea", ["getCoursesList"])
  },

  computed: {
    ...mapState("CourseArea", ["CoursesList"])
  }
};
</script>

<style lang="scss" scoped>
.course-list {
  background-color: #fff;

  .title {
    padding: 1rem;
  }

  .image-container {
    border-radius: 25px;
  }
}
</style>
